<script>
import Layout from "../_layouts/single"

/**
 * Login component
 */
export default {
  components: {
    Layout,
  },
  data() {
    return {
      selectedCompany: '88casino',
      users: [],
    }
  },
  computed: {},
  watch: {},
  methods: {
    async getUsers() {
      const loader = this.$loading.show({container: this.$refs.users})
      try {
        const result = await this.$API.site.users()
        if (result.code !== 'OK') {
          alert(result.message)
          return
        }
        this.users = result.users
      } catch (e) {
        alert(e.message)
      } finally {
        loader.hide()
      }
    },
    async play(id) {
      const loader = this.$loading.show({container: this.$refs.users})
      try {
        const result = await this.$API.site.play(id, this.selectedCompany)
        if (result.code !== 'OK') {
          alert(result.message)
          return
        }

        document.location.href = result.url
      } catch (e) {
        alert(e.message)
      } finally {
        loader.hide()
      }
    },
    // out(){
    //   if(!this.isDev){
    //     window.location.href = 'about:blank'
    //   }
    // },
  },


  mounted() {
    // this.out()
    this.getUsers().then()
  },
}
</script>

<style scoped>

</style>

<template>
  <Layout>
    <div class="container">
      <div ref="users" class="card">
        <div class="card-header text-center">
          <select v-model="selectedCompany" class="form-select">
            <option value="88casino">88카지노</option>
            <option value="cagayan">카가얀</option>
            <option value="dream_gaming">드림게이밍</option>
            <option value="dream_gaming-hotel">드림게이밍-호텔</option>
            <option value="og">오리엔탈게이밍</option>
            <option value="ag">아시안게이밍</option>
            <option value="ag-e">아시안게이밍-유럽</option>
            <option value="ag-cg">아시안게이밍-카가얀</option>
<!--            <option value="wm">WM카지노</option>-->
          </select>
        </div>
        <div class="card-body">
          <ul class="list-group">
            <li v-for="user in users" v-bind:key="user._id" class="list-group-item d-flex justify-content-between">
              <div><span>{{ user.nickname }}</span> - <span class="badge badge-soft-pink">{{ user.cash.toFloat().toThousands() }}원</span></div>
              <span><button class="btn btn-primary btn-sm" type="button" @click="play(user.id)">로그인</button></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>
